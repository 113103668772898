import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, CardText, Row, Col, Button, Input, FormGroup, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave, faX, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import CountryDropdown from '../atoms/inputs/CountryDropdown';
import {getDefaultCountryForCompanyId} from "@kjdelectronics/ps-quotetool-domain/domain/helper/country.default.js";

const TaxIdsAccountSection = ({ account, quoteToolClient, onAccountUpdated }) => {
    // Initialize with the existing tax IDs or an empty array if none exist
    const [taxIds, setTaxIds] = useState((account.taxes && account.taxes.taxIds) || []);
    const [editingIndex, setEditingIndex] = useState(null);
    const [newTaxId, setNewTaxId] = useState(null);

    const defaultCountry = getDefaultCountryForCompanyId(account.defaults.companyId);

    const handleAddNewTaxId = () => {
        setNewTaxId({
            taxId: '',
            country: '',
            stateProvinceTerritory: '',
            notes: '',
        });
        setEditingIndex('new');
    };


    const handleFieldChange = (index, field, value) => {
        console.log(index, field, value);
        if (index === 'new') {
            setNewTaxId({
                ...newTaxId,
                [field]: value,
            });
        } else {
            const updatedTaxIds = [...taxIds];
            updatedTaxIds[index][field] = value;
            setTaxIds(updatedTaxIds);
        }
    };

    const validateTaxId = (taxIdObj) => {
        return (
            taxIdObj.taxId.trim() &&
            taxIdObj.country.trim()
        );
    };

    const handleSaveTaxId = async () => {
        const taxIdToSave = editingIndex === 'new' ? newTaxId : taxIds[editingIndex];

        if (!validateTaxId(taxIdToSave)) {
            toast.error('All fields are required.');
            return;
        }

        try {
            let updatedTaxIds;
            if (editingIndex === 'new') {
                updatedTaxIds = [...taxIds, taxIdToSave];
            } else {
                updatedTaxIds = taxIds;
            }
            const updatedTaxes = {
                ...account.taxes,
                taxIds: updatedTaxIds,
            };

            const savedAccount = await quoteToolClient.patchAccount({
                accountId: account.id,
                patch: { taxes: updatedTaxes },
            });

            onAccountUpdated(savedAccount);
            setTaxIds(updatedTaxes.taxIds);
            setEditingIndex(null);
            setNewTaxId(null);
            toast.success('Tax ID saved successfully!');
        } catch (error) {
            toast.error('Failed to save Tax ID.');
            console.error(error);
        }
    };

    const handleClearSubdivision = (index) => {
        handleFieldChange(index, 'stateProvinceTerritory', '');
    };

    const handleCancelEdit = () => {
        setEditingIndex(null);
        setNewTaxId(null);
    };

    return (
        <Row>
            <Col xs="12" className="d-flex justify-content-between align-items-center mb-2">
                <h3>Tax IDs</h3>
                <Button color="primary" outline onClick={handleAddNewTaxId}>
                    <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} /> Add Tax ID
                </Button>
            </Col>
            <Row>
                {taxIds.map((taxIdObj, index) => (
                    <Col sm="6" md="4" key={index}>
                        <Card className="compact-card mb-3">
                            <CardBody>
                                <CardTitle className="d-flex justify-content-between align-items-center">
                                    <span>Tax ID {index + 1}</span>
                                    {editingIndex !== index && (
                                        <FontAwesomeIcon
                                            icon={faPencilAlt}
                                            className="edit-icon"
                                            onClick={() => setEditingIndex(index)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    )}
                                </CardTitle>
                                {editingIndex === index ? (
                                    <>
                                        <FormGroup>
                                            <Label>Tax ID <span style={{ color: 'red' }}>*</span></Label>
                                            <Input
                                                value={taxIdObj.taxId}
                                                onChange={(e) =>
                                                    handleFieldChange(index, 'taxId', e.target.value)
                                                }
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Country <span style={{ color: 'red' }}>*</span></Label>
                                            <CountryDropdown
                                                selectedCountry={taxIdObj.country}
                                                onCountryChange={({ country }) =>
                                                    handleFieldChange(index, 'country', country)
                                                }
                                                defaultCountry={defaultCountry}
                                                isSubdivision={false}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>
                                                State/Province/Territory
                                                <Button
                                                    size="sm"
                                                    color="secondary"
                                                    outline
                                                    onClick={() => handleClearSubdivision(index)}
                                                    style={{ marginLeft: '10px',fontSize: "11px", padding: '3px' }}
                                                >
                                                    Clear
                                                </Button>
                                            </Label>
                                            <CountryDropdown
                                                selectedCountry={taxIdObj.country}
                                                selectedSubdivision={taxIdObj.stateProvinceTerritory}
                                                onSubdivisionChange={({stateProvince}) =>
                                                    handleFieldChange(index, 'stateProvinceTerritory', stateProvince)
                                                }
                                                defaultCountry={defaultCountry}
                                                isSubdivision={true}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Notes</Label>
                                            <Input
                                                value={taxIdObj.notes}
                                                onChange={(e) =>
                                                    handleFieldChange(index, 'notes', e.target.value)
                                                }
                                            />
                                        </FormGroup>
                                        <div className="d-flex justify-content-between mt-2">
                                            <Button color="success" size="sm" outline onClick={handleSaveTaxId}>
                                                <FontAwesomeIcon icon={faSave} /> Save
                                            </Button>
                                            <Button size="sm" outline color="danger" onClick={handleCancelEdit}>
                                                <FontAwesomeIcon icon={faX} /> Cancel
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <CardText>
                                            <strong>Tax ID:</strong> {taxIdObj.taxId}
                                        </CardText>
                                        <CardText>
                                            <strong>Country:</strong> {taxIdObj.country}
                                        </CardText>
                                        <CardText>
                                            <strong>State/Province/Territory:</strong> {taxIdObj.stateProvinceTerritory}
                                        </CardText>
                                        <CardText>
                                            <strong>Notes:</strong> {taxIdObj.notes}
                                        </CardText>
                                    </>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                ))}
                {newTaxId && editingIndex === 'new' && (
                    <Col sm="6" md="4">
                        <Card className="compact-card mb-3">
                            <CardBody>
                                <FormGroup>
                                    <Label>Tax ID <span style={{ color: 'red' }}>*</span></Label>
                                    <Input
                                        placeholder="Tax ID"
                                        value={newTaxId.taxId}
                                        onChange={(e) =>
                                            handleFieldChange('new', 'taxId', e.target.value)
                                        }
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Country <span style={{ color: 'red' }}>*</span></Label>
                                    <CountryDropdown
                                        selectedCountry={newTaxId.country}
                                        onCountryChange={({ country }) =>
                                            handleFieldChange('new', 'country', country)
                                        }
                                        defaultCountry={defaultCountry}
                                        isSubdivision={false}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>
                                        State/Province/Territory
                                        <Button
                                            size="sm"
                                            color="secondary"
                                            outline
                                            onClick={() => handleClearSubdivision('new')}
                                            style={{ marginLeft: '10px',fontSize: "11px", padding: '3px' }}
                                        >
                                            Clear
                                        </Button>
                                    </Label>
                                    <CountryDropdown
                                        selectedCountry={newTaxId.country}
                                        selectedSubdivision={newTaxId.stateProvinceTerritory}
                                        onSubdivisionChange={({stateProvince}) =>
                                            handleFieldChange('new', 'stateProvinceTerritory', stateProvince)
                                        }
                                        defaultCountry={defaultCountry}
                                        isSubdivision={true}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Notes</Label>
                                    <Input
                                        placeholder="Notes"
                                        value={newTaxId.notes}
                                        onChange={(e) =>
                                            handleFieldChange('new', 'notes', e.target.value)
                                        }
                                    />
                                </FormGroup>
                                <div className="d-flex justify-content-between mt-2">
                                    <Button color="success" size="sm" outline onClick={handleSaveTaxId}>
                                        <FontAwesomeIcon icon={faSave} /> Save
                                    </Button>
                                    <Button color="danger" size="sm" outline onClick={handleCancelEdit}>
                                        <FontAwesomeIcon icon={faX} /> Cancel
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                )}
            </Row>
        </Row>
    );
};

TaxIdsAccountSection.propTypes = {
    account: PropTypes.object.isRequired,
    quoteToolClient: PropTypes.object.isRequired,
    onAccountUpdated: PropTypes.func.isRequired,
};

export default TaxIdsAccountSection;
