import React, { useState } from 'react';
import { Row, Col, Input, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import TaxableSwitch from './inputs/TaxableSwitch.js';
// import TaxableConflictWarning from "./AccountTaxableConflictWarning.js";
// TODO: add back conflict warning
import PropTypes from "prop-types";
import { getCompanySegment } from "@kjdelectronics/ps-quotetool-domain/domain/helper/companyUtils.js";
import TaxGroupDropdown from "./inputs/TaxGroupDropdown";
import PaymentAndGeneralTermsFlexibleDropdown from "./inputs/PaymentAndGeneralTermsFlexibleDropdown.js";

const AccountPageHeading = ({ name, account, onAccountNameChange, onTaxGroupChange, onTaxableChange,  handlePaymentTermsChange, quoteToolClient }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [currentName, setCurrentName] = useState(name || '');

    const toggleEditMode = () => setIsEditing(!isEditing);
    const handleNameBlur = () => {
        onAccountNameChange(currentName);
        setIsEditing(false);
    };

    const companySegment = getCompanySegment(account);

    const handleTaxGroupChange = (newTaxGroup) => {
        onTaxGroupChange(newTaxGroup);
    };

    const defaultTaxGroup = { id: 0, name: "Default Zone (everywhere else)" };
    const currentTaxGroup = (account.taxes && account.taxes.taxGroup) || defaultTaxGroup;

    return (
        <Row className="align-items-center mt-3">
            <Col lg={5}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h2>Account -</h2>
                    {isEditing ? (
                        <Input
                            type="text"
                            value={currentName}
                            onChange={(e) => setCurrentName(e.target.value)}
                            onBlur={handleNameBlur}
                            autoFocus
                            style={{ marginLeft: '10px', fontSize: '1.5rem', width: 'auto' }}
                        />
                    ) : (
                        <div
                            onClick={() => !name && toggleEditMode()}
                            style={{
                                marginLeft: '10px',
                                cursor: name ? 'default' : 'pointer',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <h2 style={{ margin: 0 }}>
                                {name || <i style={{ color: 'gray' }}>Add an Account Name</i>}
                            </h2>
                            <FontAwesomeIcon
                                icon={faPencilAlt}
                                onClick={toggleEditMode}
                                style={{ marginLeft: '10px', cursor: 'pointer', fontSize: '1.5rem' }}
                            />
                        </div>
                    )}
                    {/* TODO: Add back in conflict warning */}
                </div>
            </Col>
            <Col className="text-end" lg={3} md={4} sm={5}>
                <PaymentAndGeneralTermsFlexibleDropdown
                    header="Payment Terms"
                    selectedTerm={account.meta.paymentTermId}
                    onChange={(e) => handlePaymentTermsChange(parseInt(e.target.value, 10))}
                    quoteToolClient={quoteToolClient}/>
            </Col>
            <Col className="text-end" lg={3} md={4} sm={5}>
                {companySegment.toUpperCase() === "CA" ? (
                    <TaxGroupDropdown
                        taxGroup={currentTaxGroup}
                        onTaxGroupChange={handleTaxGroupChange}
                    />
                ) : (
                    <TaxableSwitch
                        isTaxable={account.taxes && account.taxes.isTaxable}
                        onChange={onTaxableChange}
                        text="Taxable"
                    />
                )}
            </Col>
            <Col className="text-end" lg={1}>
                {companySegment ? (
                    <Link to={`/${companySegment}/quotes?accountId=${account.id}`} style={{ textDecoration: 'none' }}>
                        <Button
                            size="sm"
                            outline
                            style={{
                                color: 'inherit',
                                textDecoration: 'none',
                                borderColor: 'green',
                                marginLeft: '15px'
                            }}
                        >
                            <FontAwesomeIcon icon={faPlus} style={{ color: 'green', marginRight: '5px' }} /> New Quote
                        </Button>
                    </Link>
                ) : (
                    <Button
                        size="sm"
                        outline
                        disabled
                        style={{
                            color: 'grey',
                            textDecoration: 'none',
                            borderColor: 'grey',
                            marginLeft: '15px'
                        }}
                        title="Company or Store ID is required to create a new quote."
                    >
                        <FontAwesomeIcon icon={faPlus} style={{ color: 'grey', marginRight: '5px' }} /> New Quote
                    </Button>
                )}
            </Col>
        </Row>
    );
};

AccountPageHeading.propTypes = {
    name: PropTypes.string,
    account: PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaults: PropTypes.shape({
            companyId: PropTypes.number,
            storeId: PropTypes.number,
        }),
        taxes: PropTypes.shape({
            isTaxable: PropTypes.bool,
            taxGroup: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
            }),
        }),
    }).isRequired,
    onAccountNameChange: PropTypes.func.isRequired,
    onTaxGroupChange: PropTypes.func.isRequired,
    onTaxableChange: PropTypes.func.isRequired,
};

export default AccountPageHeading;