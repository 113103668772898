import React from "react";
import {Badge, Button, ButtonGroup} from "reactstrap";
import {formatStatusStringForDisplay} from "../../helper/formatters.js";
import QUOTE_STATUS_COLORS from "../../domain/quote.status.colors.js";
import QuoteStatusBadge from "../atoms/QuoteStatusBadge.js";

const QuoteStatusActions = ({ companyUrlSegment, quoteStatus, onStatusChange }) => {
    return (
        <div>
            <span className="me-3">Current Status: <QuoteStatusBadge status={quoteStatus.current}/></span>
            {Object.entries(quoteStatus.next)
                .filter(([newStatus, label]) => newStatus !== "EXPIRED") //Do not allow manually expiring
                .map(([newStatus, label]) => (
                    StatusButton({companyUrlSegment, newStatus, label, onStatusChange})
            ))}
        </div>
    );
};

const StatusButton = ({ companyUrlSegment, newStatus, label, onStatusChange }) => {
    if(companyUrlSegment.toUpperCase() !== "US" && newStatus === "CONVERTED_TO_ORDER"){
        return <Badge pill >
            Convert to Order Not Yet Available for CA
        </Badge>
    }

    return (
        <Button key={newStatus} color={QUOTE_STATUS_COLORS[newStatus]} className="mx-2" size="sm" onClick={() => onStatusChange(newStatus)}>
            {label}
        </Button>
    );
};


export default QuoteStatusActions;
