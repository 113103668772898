import React from 'react';
import {Alert, Button} from "reactstrap";
import {getUrlSegmentFromCompanyId} from "@kjdelectronics/ps-quotetool-domain/obj/saturn/SaturnCompanyUtils";
import {cloneQuoteAndOpen} from "../../helper/quote.cloner.js";
import {useNavigate} from "react-router-dom";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const QuoteLockedAlert = ({ quote, quoteToolClient }) => {
    const rawSegment = getUrlSegmentFromCompanyId(quote.companyId);
    const companyUrlSegment = rawSegment ? rawSegment.replace('-LEGACY', '') : null;
    const navigate = useNavigate();

    const viewPdf = function(){
        const pdfUrl = `/v1/${companyUrlSegment}/quotes/${quote.ref}.pdf`;
        window.open(pdfUrl);
    }

    const cloneQuote = function(){
        return cloneQuoteAndOpen({quote, quoteToolClient, navigate, newTab: false});
    }

    if (!quote.isLocked)
        return;
    return (
        <Alert>
            <h6 className="text-center">
                This quote has been converted to order{" "}
                <a
                    href={`https://us.pishop.com/admin/orders/${quote.createdOrder.id}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {quote.createdOrder.id}
                </a>{" "}
                and cannot be modified.
                <br/>
                If changes are needed, cancel the order and clone this quote.
            </h6>
            <div className="text-center mt-3">
                <Button color="primary" onClick={viewPdf} className="me-2">
                    View PDF
                </Button>
                <Button color="secondary" onClick={cloneQuote}>
                    Clone
                </Button>
            </div>
            <div className="text-center mt-4">
                <FontAwesomeIcon icon={faLock}
                                 style={{color: 'grey', fontSize: "54pt"}}/>
            </div>
        </Alert>
    );
}
export default QuoteLockedAlert;
