import React, {useContext, useEffect, useState} from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Pagination, PaginationItem, PaginationLink, Table, FormGroup, Label, Input, Button } from 'reactstrap';
import { getCurrencyFormatter } from "../../../helper/formatters";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import FullScreenLoadingSpinner from "../../atoms/loading/FullScreenLoadingSpinner";
import {cloneQuoteAndOpen} from "../../../helper/quote.cloner.js";
import AppContext from "../../../context/AppContext";
import QuoteStatusBadge from "../../atoms/QuoteStatusBadge.js";
import {COMPANY_ID_SEGMENT_MAP} from "@kjdelectronics/ps-quotetool-domain/domain/helper/companyUtils.js";

function QuoteTable({ quoteToolClient, filter, companyUrlSegment }) {
    const [quotes, setQuotes] = useState([]);
    const [error, setError] = useState(null);
    const [sortKey, setSortKey] = useState('ref');
    const [sortOrder, setSortOrder] = useState('desc');
    const [filterStoreUS, setFilterStoreUS] = useState(false);
    const [filterStoreCA, setFilterStoreCA] = useState(false);
    const [filterEmail, setFilterEmail] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [quotesPerPage, setQuotesPerPage] = useState(10);
    const [isCloning, setIsCloning] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useContext(AppContext);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const companyParam = params.get('company');

        if (companyParam === 'US') {
            setFilterStoreUS(true);
        } else if (companyParam === 'CA') {
            setFilterStoreCA(true);
        }
    }, [location.search]);

    useEffect(() => {
        const fetchQuotes = async () => {
            try {
                if (quoteToolClient) {
                    // Build the filters object based on the state
                    const filters = filter || {};

                    // Apply store filters if selected
                    const companyId = [];
                    if (filterStoreUS) companyId.push(1000104959);
                    if (filterStoreCA) companyId.push(1000099708);
                    if (companyId.length > 0) {
                        filters.$or = [
                            { companyId: { "$in": companyId } },
                            { storeId: { "$in": companyId } }
                        ]
                    }

                    // Pass search term directly (searching is handled on the backend)
                    const searchTerm = filterEmail || '';

                    // Call the API with sorting and filtering parameters
                    const { quotes, totalCount } = await quoteToolClient.getQuotes({
                        companyUrlSegment,
                        page,
                        limit: quotesPerPage,
                        search: searchTerm,
                        filter: filters,
                        sortKey,
                        sortOrder,
                    });

                    console.log(quotes);
                    setQuotes(quotes);
                    setTotalPages(Math.ceil(totalCount / quotesPerPage));
                } else {
                    setError('QuoteToolClient is not initialized.');
                }
            } catch (err) {
                if (err?.response?.status === 403) {
                    setError(`${user?.firstName || 'User'}, you lack the correct role to view the data you are requesting.`);
                } else {
                    console.error('Error fetching quotes:', err);
                    setError('Error fetching quotes. Please try again later.');
                }
            }
        };

        fetchQuotes();
    }, [quoteToolClient, page, quotesPerPage, sortKey, sortOrder, filterStoreUS, filterStoreCA, filterEmail, companyUrlSegment]);

    // Reset page to 1 when filters or sorting change
    useEffect(() => {
        setPage(1);
    }, [quotesPerPage, sortKey, sortOrder, filterStoreUS, filterStoreCA, filterEmail]);

    const handleSortChange = (e) => {
        setSortKey(e.target.value);
    };

    const handleSortOrderChange = (e) => {
        setSortOrder(e.target.value);
    };

    const handleStoreUSChange = (e) => {
        setFilterStoreUS(e.target.checked);
    };

    const handleStoreCAChange = (e) => {
        setFilterStoreCA(e.target.checked);
    };

    const handleEmailFilterChange = (e) => {
        setFilterEmail(e.target.value);
    };

    const handlePageChange = (newPage) => {
        if (newPage !== page) {
            setPage(newPage);
        }
    };

    const handleQuotesPerPageChange = (event) => {
        const newQuotesPerPage = parseInt(event.target.value, 10);
        setQuotesPerPage(newQuotesPerPage);
        setPage(1);
    };

    const generatePageNumbers = () => {
        const pages = [];
        const startPage = Math.max(1, page - 2);
        const endPage = Math.min(totalPages, page + 2);

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        return pages;
    };

    const saveAndOpenPdf = async (quote, companyUrlSegment) => {
        if (quote && quote.ref) {
            try {
                const pdfUrl = `/v1/${companyUrlSegment}/quotes/${quote.ref}.pdf`;
                window.open(pdfUrl);
            } catch (error) {
                console.error(error);
                toast.error(`Failed to render PDF for quote ${quote.ref}`);
            }
        }
    };

    const cloneAndOpen = async (quote) => {
        if (quote && quote.ref) {
            try {
                setIsCloning(true);
                await cloneQuoteAndOpen({quote, quoteToolClient, navigate, newTab: false});
            } catch (error) {
                console.error(error);
                toast.error(`Failed to clone quote ${quote.ref}`);
            }
        }
    };

    return (
        <div>
            {error ? (
                <p>Error: {error}</p>
            ) : (
                <div>
                    {isCloning ? <FullScreenLoadingSpinner/> : ""}
                    <div className="filters">
                        <div className="filter-item">
                            <label>
                                Sort by:
                                <select value={sortKey} onChange={handleSortChange}>
                                    <option value="ref">Ref#</option>
                                    <option value="createdDate">Created Date</option>
                                </select>
                            </label>
                            <label>
                                Order:
                                <select value={sortOrder} onChange={handleSortOrderChange}>
                                    <option value="asc">Ascending</option>
                                    <option value="desc">Descending</option>
                                </select>
                            </label>
                        </div>

                        {/* We are dividing the app into two companies and as such no longer need these filters
                        <div className="filter-item">
                            <label>Filter by Company:</label>
                            <div>
                                <label>
                                    <Input
                                        type="checkbox"
                                        checked={filterStoreUS}
                                        onChange={handleStoreUSChange}
                                    />
                                    US Company
                                </label>
                            </div>
                            <div>
                                <label>
                                    <Input
                                        type="checkbox"
                                        checked={filterStoreCA}
                                        onChange={handleStoreCAChange}
                                    />
                                    Canada Company
                                </label>
                            </div>
                        </div>
                        */}

                        <div className="filter-item">
                            <label>
                                Filter by Email:
                                <Input
                                    type="text"
                                    value={filterEmail}
                                    onChange={handleEmailFilterChange}
                                    placeholder="Enter Customer Email"
                                />
                            </label>
                        </div>
                    </div>
                    <div className="quote-table-container">
                        <Table hover striped className="table-responsive">
                            <thead>
                            <tr>
                                <th>Ref#</th>
                                <th>Account</th>
                                <th style={{ "width": '7%' }}>Status</th>
                                <th>Total</th>
                                <th>Profit %</th>
                                <th>Notes</th>
                                <th>Created Date</th>
                                <th style={{ "width": '10%' }}>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {quotes && quotes.length > 0 ? (
                                quotes.map(quote => {
                                    const segment = COMPANY_ID_SEGMENT_MAP[quote.companyId] || 'US';
                                    const cleanSegment = companyUrlSegment.replace('-LEGACY', '');
                                    return (
                                        <tr key={quote.ref}>
                                            <td>
                                                <Link to={`/${segment}/quotes/${quote.ref}`}>
                                                    {quote.ref}
                                                </Link>
                                            </td>
                                            <td>
                                                <Link
                                                    className=""
                                                    target="_blank"
                                                    to={`/${cleanSegment}/accounts/${quote.account.id}`}
                                                >
                                                    <Button color="link">{quote.account.toString()}</Button>
                                                </Link>

                                            </td>
                                            <td><QuoteStatusBadge status={quote.status.current}/></td>
                                            <td>
                                                {getCurrencyFormatter(quote.currencyCode)(quote.totals.grand)} {quote.currencyCode}
                                            </td>
                                            <td className={quote.totals.profitPercentage >= 0 ? 'text-success' : 'text-danger'}>
                                                {quote.totals.profitPercentage
                                                    ? `${(quote.totals.profitPercentage * 100).toFixed(2)}%`
                                                    : 'N/A'}
                                            </td>
                                            <td>{quote.detail.notes}</td>
                                            <td>{new Date(quote.audit.createdDate).toLocaleDateString()}</td>
                                            <td>
                                                <div className="button-container">
                                                    <Button
                                                        color="primary"
                                                        size="sm"
                                                        onClick={() => saveAndOpenPdf(quote, companyUrlSegment)}
                                                    >
                                                        PDF
                                                    </Button>
                                                    <Button
                                                        color="primary"
                                                        outline={true}
                                                        size="sm"
                                                        onClick={() => cloneAndOpen(quote)}
                                                        disabled={isCloning}
                                                    >
                                                        Clone
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan="8">No quotes found.</td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </div>

                    <Pagination aria-label="Quotes navigation">
                        <PaginationItem disabled={page <= 1}>
                            <PaginationLink
                                first
                                onClick={() => handlePageChange(1)}
                            />
                        </PaginationItem>
                        <PaginationItem disabled={page <= 1}>
                            <PaginationLink
                                previous
                                onClick={() => handlePageChange(page - 1)}
                            />
                        </PaginationItem>
                        {generatePageNumbers().map((p) => (
                            <PaginationItem active={p === page} key={p}>
                                <PaginationLink onClick={() => handlePageChange(p)}>
                                    {p}
                                </PaginationLink>
                            </PaginationItem>
                        ))}
                        <PaginationItem disabled={page >= totalPages}>
                            <PaginationLink
                                next
                                onClick={() => handlePageChange(page + 1)}
                            />
                        </PaginationItem>
                        <PaginationItem disabled={page >= totalPages}>
                            <PaginationLink
                                last
                                onClick={() => handlePageChange(totalPages)}
                            />
                        </PaginationItem>
                    </Pagination>

                    <FormGroup>
                        <Label for="quotesPerPageSelect">Quotes per page:</Label>
                        <Input
                            type="select"
                            id="quotesPerPageSelect"
                            value={quotesPerPage}
                            onChange={handleQuotesPerPageChange}
                        >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                        </Input>
                    </FormGroup>

                </div>
            )}
        </div>
    );
}

QuoteTable.propTypes = {
    quoteToolClient: PropTypes.object.isRequired,
    filter: PropTypes.object,
    companyUrlSegment: PropTypes.string,
};

export default QuoteTable;