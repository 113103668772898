import {toast} from "react-toastify";
import {getUrlSegmentFromCompanyId} from "@kjdelectronics/ps-quotetool-domain/obj/saturn/SaturnCompanyUtils.js";

const cloneQuoteAndOpen = async ({quote, quoteToolClient, navigate, newTab=true}) => {
    try {
        const segment = getUrlSegmentFromCompanyId(quote.companyId);
        const newQuote = await quoteToolClient.cloneQuoteByRef({ref: quote.ref, companyUrlSegment: segment});
        let url = `/${segment}/quotes/${newQuote.ref}`;
        if(newTab) {
            url = url.replace('-LEGACY', ""); //Do not route to legacy. Will map automatically
            window.open(url, '_blank', 'noreferrer').focus();
        }
        else
            navigate(url);
    } catch (error) {
        console.error(error);
        toast.error(`Failed to clone quote ${quote.ref}`);
    }
};

export {cloneQuoteAndOpen}