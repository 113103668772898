import React from 'react';
import PropTypes from 'prop-types';
import QuoteLineItemRow from '../molecules/QuoteLineItemRow.js';
import { Button } from "reactstrap";
import ReorderModal from "../molecules/modals/ReorderModal.js";
import { FALLBACK_CURRENCY_RATES } from "@kjdelectronics/ps-quotetool-domain/obj/quote/CurrencyRates";
import QuoteTotals from "../molecules/QuoteTotals.js";
import LoadingSpinner from "../atoms/loading/LoadingSpinner.js";
import MultipleSkuModal from "../molecules/modals/MultipleSkuModal.js";
import { toast } from "react-toastify";
import QuoteLineItem from "@kjdelectronics/ps-quotetool-domain/obj/quote/QuoteLineItem";

class ProductForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            discountValue: this.props.quote.totalsDiscount ? this.props.quote.totalsDiscount : '',
            discountPercentage: this.props.quote.discountPercent ? this.props.quote.discountPercent : '',
            shippingPrice: this.props.quote.shipping.shippingPrice !== null &&
            this.props.quote.shipping.shippingPrice !== undefined
                ? this.props.quote.shipping.shippingPrice.toString()
                : '',
            isModalOpen: false,
            showTooltip: false,
            rates: props.currencyRates ? props.currencyRates : FALLBACK_CURRENCY_RATES,
            isMultipleSkuModalOpen: false,
            isLoading: false,
            loadingProgress: 0,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.quote !== this.props.quote) {
            this.setState({
                discountValue: this.props.quote.totalsDiscount ? this.props.quote.totalsDiscount : '',
                discountPercentage: this.props.quote.discountPercent ? this.props.quote.discountPercent : '',
                shippingPrice: this.props.quote.shipping.shippingPrice !== null &&
                this.props.quote.shipping.shippingPrice !== undefined
                    ? this.props.quote.shipping.shippingPrice.toString()
                    : '',
            });
        }
    }

    toggleModal = () => {
        if (!this.props.lineItems || this.props.lineItems.length === 0) {
            console.warn('No line items available to reorder.');
            return;
        }
        this.setState(prevState => ({ isModalOpen: !prevState.isModalOpen }));
    };

    toggleMultipleSkuModal = () => {
        this.setState(prevState => ({ isMultipleSkuModalOpen: !prevState.isMultipleSkuModalOpen }));
    };

    updateValue = (value, keyArray, decimalPlaces = 2) => {
        const parsedValue = parseFloat(value);
        const validValue = isNaN(parsedValue) ? 0 : parsedValue.toFixed(decimalPlaces);
        this.props.patchQuote({
            quote: this.props.quote,
            keyArray: keyArray,
            newValue: validValue,
        });
    };

    handleReorderLineItems = (newOrder) => {
        const reorderedItems = newOrder.map((item, index) => ({
            ...item,
            order: index,
        }));
        this.props.patchQuote({
            quote: this.props.quote,
            keyArray: ['lineItems'],
            newValue: reorderedItems,
        });
    };

    /**
     * UPDATED addMultipleSkusToQuote:
     *
     * This method handles both raw SKU strings and validated product objects.
     * It processes each SKU (if necessary), logs the progress,
     * wraps each item with new QuoteLineItem(…) and then patches the quote
     * using PatchQuote.patchQuoteAction.
     * Finally, it calls the setQuote callback to update the parent state.
     */
    async addMultipleSkusToQuote(skus) {
        try {
            console.log("addMultipleSkusToQuote called with SKUs:", skus);
            this.setState({ isLoading: true, loadingProgress: 0 });
            let newLineItems = [];

            // If SKUs are already objects (validated products), use them directly.
            if (skus.length > 0 && typeof skus[0] === 'object') {
                newLineItems = skus.map(item => ({ ...item, isFromMultipleSkuModal: true }));
            } else {
                const totalSkus = skus.length;
                let processedSkus = 0;
                newLineItems = await Promise.all(
                    skus.map(async (sku) => {
                        let product = null;
                        try {
                            product = await this.props.quoteToolClient.getProductLineItemBySku({
                                companyUrlSegment: this.props.companyUrlSegment,
                                sku
                            });
                        } catch (error) {
                            console.error("Error fetching product for SKU", sku, ":", error);
                        }
                        if (!product) {
                            console.log("No product returned for SKU", sku, "—creating custom line item.");
                            product = {
                                id: null,
                                sku: sku,
                                title: "",
                                quantity: 1,
                                isFromMultipleSkuModal: true, // custom SKUs are marked as such
                                isCustomItem: true,
                            };
                        }
                        processedSkus += 1;
                        const progress = Math.round((processedSkus / totalSkus) * 100);
                        this.setState({ loadingProgress: progress });
                        return product;
                    })
                );
            }

            const wrappedLineItems = newLineItems.map(item => {
                const wrappedItem = new QuoteLineItem(item, this.props.quote);
                wrappedItem.isFromMultipleSkuModal = true; // force lock SKU and Title fields
                return wrappedItem;
            });
            console.log("Wrapped line items:", wrappedLineItems);

            this.props.patchQuoteAction({
                action: "addMultipleItems",
                data: wrappedLineItems,
            });

            toast.success("All SKUs added successfully!");
        } catch (error) {
            console.error("Error adding SKUs:", error);
            toast.error("Failed to add SKUs.");
        } finally {
            this.setState({ isLoading: false, loadingProgress: 0 });
        }
    }

    toggleTooltip = () => {
        this.setState(prevState => ({ showTooltip: !prevState.showTooltip }));
    };

    render() {
        const { lineItems, quoteToolClient, quote } = this.props;
        const { discountValue, discountPercentage, isModalOpen, rates, isMultipleSkuModalOpen } = this.state;
        const profitClass = quote.profitTotal >= 0 ? 'profit-positive' : 'profit-negative';
        const isUSCompany = quote.companyId === 1000104959;
        const sortedLineItems = (lineItems || []).slice().sort((a, b) => a.order - b.order);

        return (
            <div className="product-form-container">
                <LoadingSpinner
                    isLoading={this.state.isLoading}
                    message="Adding SKUs..."
                    fullPage={true}
                    progress={this.state.loadingProgress}
                />
                <div className="small-form-container ms-5 me-5">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ marginRight: "10px" }}>Items</h3>
                        <Button bsSize="sm" color="primary" onClick={this.toggleModal}>
                            Reorder Line Items
                        </Button>
                    </div>
                    <form>
                        <table className="table form-table">
                            <thead>
                            <tr>
                                <th style={{ width: '3%' }}>#</th>
                                <th style={{ width: '5%' }}>SKU</th>
                                <th style={{ width: '25%' }}>Title</th>
                                <th style={{ width: '6%' }}>Quantity</th>
                                <th style={{ width: '7%' }}>Inventory (BO?)</th>
                                <th style={{ width: '5%' }}>Unit Cost</th>
                                <th style={{ width: '5%' }}>Retail Price</th>
                                <th style={{ width: '10%' }}>Discount</th>
                                <th style={{ width: '11%' }}>Price ($)</th>
                                <th style={{ width: '10%' }}>Profit</th>
                                <th>Extended (Subtotal)</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody className="quote-line-item-table-compact">
                            {sortedLineItems.map((lineItem, index) => (
                                <QuoteLineItemRow
                                    key={lineItem.id || lineItem.tempId || index}
                                    lineItem={lineItem}
                                    index={index}
                                    lineNumber={index + 1}
                                    quoteToolClient={quoteToolClient}
                                    patchQuote={this.props.patchQuote}
                                    patchQuoteAction={this.props.patchQuoteAction}
                                    patchLineItem={this.props.patchLineItem}
                                    setLineItem={this.props.setLineItem}
                                    quote={quote}
                                    isUSCompany={isUSCompany}
                                    companyId={this.props.companyId}
                                    updateValue={this.updateValue}
                                    companyUrlSegment={this.props.companyUrlSegment}
                                />
                            ))}
                            {quote ? (
                                <QuoteTotals patchQuote={this.props.patchQuote} quote={this.props.quote} rates={rates} />
                            ) : (
                                <LoadingSpinner />
                            )}
                            </tbody>
                        </table>
                        <div className="text-start mt-3">
                            <Button
                                className="add-line-item-button"
                                onClick={() => {
                                    this.props.patchQuoteAction({ action: "addItem", data: {}, quote: this.props.quote });
                                }}
                            >
                                Add Line Item
                            </Button>
                            <Button
                                className="add-multiple-sku-button"
                                onClick={this.toggleMultipleSkuModal}
                            >
                                Add Multiple SKUs
                            </Button>
                        </div>
                    </form>
                </div>
                <ReorderModal
                    isOpen={this.state.isModalOpen}
                    toggle={this.toggleModal}
                    lineItems={lineItems}
                    saveOrder={this.handleReorderLineItems}
                />

                <MultipleSkuModal
                    isOpen={this.state.isMultipleSkuModalOpen}
                    toggle={this.toggleMultipleSkuModal}
                    handleSkuAdd={this.addMultipleSkusToQuote.bind(this)}
                    quoteToolClient={quoteToolClient}
                    companyId={this.props.companyId}
                    companyUrlSegment={this.props.companyUrlSegment}
                />
            </div>
        );
    }
}

ProductForm.propTypes = {
    quoteToolClient: PropTypes.object.isRequired,
    companyId: PropTypes.number.isRequired,
    lineItems: PropTypes.array.isRequired,
    patchQuote: PropTypes.func.isRequired,
    patchQuoteAction: PropTypes.func.isRequired,
    patchLineItem: PropTypes.func.isRequired,
    setLineItem: PropTypes.func.isRequired,
    quote: PropTypes.object.isRequired,
    isUSCompany: PropTypes.bool.isRequired,
};

export default ProductForm;