import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, InputGroup, FormFeedback, FormText, Row, Alert} from 'reactstrap';
import AccountSearchInput from '../atoms/inputs/AccountSearchInput.js';
import {Link} from 'react-router-dom';
import Account from '@kjdelectronics/ps-quotetool-domain/obj/account/Account';
import AccountSearchModal from "../molecules/modals/AccountSearchModal.js";
import NewAccountModal from "../molecules/modals/NewAccountModal.js";
import notFound404ErrorHandler from "../../domain/not.found.404.handler.js";
import { HashLink } from "react-router-hash-link";
import {getUrlSegmentFromCompanyId} from "@kjdelectronics/ps-quotetool-domain/obj/saturn/SaturnCompanyUtils.js";

const AccountSelector = ({
                            quote,
                            setAccount,
                            defaultAccountId,
                            quoteToolClient,
                            showDocumentsLabel
                        }) => {

    const defaultAccountInputValue = quote.ref && !quote.account.id && quote.account.contacts?.length > 0 ? quote.account.contacts[0].email : "";
    const [accountEmailInputValue, setAccountEmailInputValue] = useState(defaultAccountInputValue);
    const [isAccountSearchModalOpen, setIsAccountSearchModalOpen] = useState(false);
    const [isNewAccountModalOpen, setIsNewAccountModalOpen] = useState(false);
    const [searchResults, setSearchResults] = useState(undefined);
    const [invalidCustomer, setInvalidCustomer] = useState(false);

    const rawSegment = getUrlSegmentFromCompanyId(quote.companyId);
    const URL_SEGMENT = rawSegment ? rawSegment.replace('-LEGACY', '') : null;

    useEffect(() => {
        if(defaultAccountId && searchResults === undefined ) {
            defaultAccountById(defaultAccountId);
        }

        //Do an initial search using the input text but only on load (when searchResults is defaulted to undefined)
        if (accountEmailInputValue && searchResults === undefined) {
            handleAccountSearch(false);
        }
        if (quote.account.id) {
            quoteToolClient.getAccountByIdOrReference(quote.account.id)
                .then(account => {
                    _setAccount(account);
                })
                .catch(error => {
                    console.error("Failed to get Account for ID in search Query", error);
                });
        }
    }, [accountEmailInputValue, defaultAccountId, quoteToolClient]);

    const defaultAccountById = async (accountId) => {
        const account = await quoteToolClient.getAccountByIdOrReference(accountId);
        if(account)
            setAccount(account);
    };

    const handleAccountSearch = useCallback(async (openModal = true) => {
        try {
            if(accountEmailInputValue === "" || accountEmailInputValue.length === 1){
                return;
            }

            const searchResults = await quoteToolClient.getAccounts(accountEmailInputValue);
            if (searchResults.length === 1) {
                _setAccount(searchResults[0]);
            } else if (openModal && searchResults.length === 0) {
                setIsNewAccountModalOpen(true);
                setSearchResults(searchResults);
            } else {
                setIsAccountSearchModalOpen(openModal && searchResults.length !== 0);
                setSearchResults(searchResults);
            }
        } catch (error) {
            notFound404ErrorHandler(error);
        }
    }, [accountEmailInputValue, quoteToolClient]);

    const handleEmailChange = (event) => {
        const email = event.target.value;
        setAccountEmailInputValue(email);
        setSearchResults(null);
    };

    const toggleNewAccountModal = () => {
        setIsNewAccountModalOpen(!isNewAccountModalOpen);
    };

    /**
     * Internal set account to manage the state of the component. Always calls the param setAccount function
     * @param selectedAccount
     * @returns {Promise<void>}
     * @private
     */
    const _setAccount = (selectedAccount) => {
        setIsNewAccountModalOpen(false);
        setIsAccountSearchModalOpen(false);
        setAccountEmailInputValue("");

        setAccount(selectedAccount);
    };

    const accountDocumentsLabel = (account) => {
        if(!account || !account.id || !account.hasOwnProperty("documents"))
            return null;

        return <span className="fst-italic">
        Account has <HashLink smooth to={`/${URL_SEGMENT}/accounts/${account.id}#documents`}>
        {account.documents.length} documents
    </HashLink>
    </span>
    }

    const showAccountSearch = !quote.account?.id;

    return (
        <div className="mb-3">
            {quote.ref && !quote.account.id && quote.account.contacts?.length > 0 && (
                <div className="text-center">
                    <Alert className="text-center mb-0" color="danger">
                        <strong>Account Required: </strong>This quote was created prior to implementation of the Accounts system. Search for an existing account
                        or click New Account.
                    </Alert>
                    <span className="text-center fw-bold" style={{color: "grey"}}>Legacy Customer Email: {quote.account.contacts[0].email}</span>
                </div>
            )}
            {/* Account */}
            <div className="form-group row align-items-center mb-1">
                <label htmlFor="staticEmail" className="col-sm-3 col-form-label">Account<span style={{color: 'red'}}>*</span></label>
                <div className="col-sm-9">
                    <Row>
                        <Col xs={12} sm={8}>
                            {showAccountSearch && (
                                <InputGroup>
                                    <AccountSearchInput
                                        value={accountEmailInputValue}
                                        invalid={invalidCustomer && !isAccountSearchModalOpen}
                                        handleChange={e => handleEmailChange(e)}
                                        autoSearchTimeoutMs={300}
                                        triggerAccountSearchCallback={({openModal}) => handleAccountSearch(openModal)}
                                    />
                                    <Button type="button" className="btn btn-primary rounded mb-2 mb-sm-0" onClick={() => handleAccountSearch()}>
                                        Search
                                    </Button>
                                    <FormFeedback>
                                        Account not found. Enter exact contact email, account name, or account number.
                                    </FormFeedback>
                                    {false && <FormText>
                                        Email, account name, or account name
                                    </FormText>}
                                    {accountEmailInputValue && searchResults && searchResults.length !== 1 && (
                                        <FormText className="warning-color">
                                            <div>{searchResults.length} Account{searchResults.length !== 1 ? 's' : ""} found for this search.
                                                {searchResults.length > 1 ? " Press Enter to see results" : " Press Enter to Create a New Account"}
                                            </div>
                                        </FormText>
                                    )}
                                </InputGroup>
                            )}
                            {quote.account?.id && (
                                <div className="text-center">
                                    <Link className="navbar-link" target="_blank" to={`/${URL_SEGMENT}/accounts/${quote.account.id}`}>
                                        <Button color="link">{quote.account.toString()}</Button>
                                    </Link>
                                    <span className="short-code" title={quote.account.formattedShortCode}>{quote.account.formattedShortCode}</span>
                                    <div>{showDocumentsLabel && accountDocumentsLabel(quote.account)}</div>
                                </div>
                            )}
                        </Col>
                        <Col xs={12} sm={4} className="">
                            {!quote.account?.id ? (
                                <Button type="button" className="new-customer-button mb-2 mb-sm-0" onClick={toggleNewAccountModal}>
                                    New Account
                                </Button>
                            ) : (
                                <Button type="button" className="btn btn-danger" onClick={() => setAccount(new Account({}))}>
                                    X
                                </Button>
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
            <AccountSearchModal
                isOpen={isAccountSearchModalOpen}
                onClose={() => setIsAccountSearchModalOpen(false)}
                onAccountSelected={_setAccount}
                searchResults={searchResults}
                onAddNewAccount={toggleNewAccountModal}
                email={accountEmailInputValue}
            />

            {isNewAccountModalOpen && <NewAccountModal
                isOpen={isNewAccountModalOpen}
                onClose={toggleNewAccountModal}
                onAccountSelected={_setAccount}
                quoteToolClient={quoteToolClient}
                email={accountEmailInputValue}
                companyId={quote.companyId}
            />}
        </div>
    );
};

AccountSelector.propTypes = {
    quote: PropTypes.object.isRequired,
    setAccount: PropTypes.func.isRequired,
    quoteToolClient: PropTypes.object.isRequired,
    showDocumentsLabel: PropTypes.bool,
};

export default AccountSelector;
