import React from 'react';
import PropTypes from 'prop-types';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Row,
    Col, Badge,
} from 'reactstrap';

const TaxCalculationModal = ({ isOpen, toggle, taxData=null, currencyFormatter, currencyCode }) => {
    if(!taxData)
        return "";

    const percentFormatter = new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 2,
    });

    // Destructure taxData for ease of use
    const {
        response: {
            data: {
                amount_to_collect,
                freight_taxable,
                has_nexus,
                jurisdictions,
                order_total_amount,
                rate,
                shipping,
                tax_source,
                taxable_amount,
            },
            val: {
                store_id,
                from_country,
                from_zip,
                from_state,
                from_city,
                from_street,
                to_zip,
                to_state,
                shipping: valShipping,
                amount,
                to_country,
            },
            key,
        },
        date,
    } = taxData;


    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
            <ModalHeader toggle={toggle}>
                Tax Calculation Details
            </ModalHeader>
            <ModalBody>
                <Row className="mb-4">
                    <Col md="6">
                        <h5 className="mb-3">Tax Summary</h5>
                        <p><strong>Amount to Collect:</strong>  <h4
                            className={"fw-bold text-success"}>{currencyFormatter(amount_to_collect)} {currencyCode}</h4>
                        </p>
                        <p><strong>Order Total Amount:</strong> {currencyFormatter(order_total_amount)} {currencyCode}
                        </p>
                        <p><strong>Taxable Amount:</strong> {currencyFormatter(taxable_amount)} {currencyCode}</p>
                        <p><strong>Rate:</strong> {percentFormatter.format(rate)}</p>
                        <p><strong>Tax Source:</strong> {tax_source}</p>
                        <p>
                            <strong>Has Nexus:</strong>{' '}
                            <Badge color={has_nexus ? 'success' : 'danger'}>
                                {has_nexus ? 'Yes' : 'No'}
                            </Badge>
                        </p>
                        <p>
                            <strong>Shipping Taxable:</strong>{' '}
                            <Badge color={freight_taxable ? 'success' : 'danger'}>
                                {freight_taxable ? 'Yes' : 'No'}
                            </Badge>
                        </p>
                        <p><strong>Shipping:</strong> {currencyFormatter(shipping)} {currencyCode}</p>
                    </Col>
                    <Col md="6">
                        <h5 className="mb-3">Jurisdictions</h5>
                        <p><strong>City:</strong> {jurisdictions?.city}</p>
                        <p><strong>County:</strong> {jurisdictions?.county}</p>
                        <p><strong>State:</strong> {jurisdictions?.state}</p>
                        <p><strong>Country:</strong> {jurisdictions?.country}</p>
                    </Col>
                </Row>
                <hr className="mb-4" />
                <Row>
                    <Col>
                        <h5 className="mb-3">Additional Information</h5>
                        <p>
                            <strong>From:</strong> {from_street}, {from_city}, {from_state} {from_zip}, {from_country}
                        </p>
                        <p>
                            <strong>To:</strong> {to_zip}, {to_state}, {to_country}
                        </p>
                        <p><strong>Store ID:</strong> <Badge>{store_id}</Badge></p>
                        <p><strong>Key:</strong>  <Badge>{key}</Badge></p>
                        <p>
                            <strong>Calculation Date:</strong> {new Date(date).toLocaleString()}
                        </p>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

TaxCalculationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    currencyFormatter: PropTypes.bool.isRequired,
    currencyCode: PropTypes.func.isRequired,
    taxData: PropTypes.shape({
        response: PropTypes.shape({
            data: PropTypes.shape({
                amount_to_collect: PropTypes.number.isRequired,
                freight_taxable: PropTypes.bool.isRequired,
                has_nexus: PropTypes.bool.isRequired,
                jurisdictions: PropTypes.shape({
                    city: PropTypes.string.isRequired,
                    country: PropTypes.string.isRequired,
                    county: PropTypes.string.isRequired,
                    state: PropTypes.string.isRequired,
                }).isRequired,
                order_total_amount: PropTypes.number.isRequired,
                rate: PropTypes.number.isRequired,
                shipping: PropTypes.number.isRequired,
                tax_source: PropTypes.string.isRequired,
                taxable_amount: PropTypes.number.isRequired,
            }).isRequired,
            val: PropTypes.shape({
                store_id: PropTypes.number.isRequired,
                from_country: PropTypes.string.isRequired,
                from_zip: PropTypes.string.isRequired,
                from_state: PropTypes.string.isRequired,
                from_city: PropTypes.string.isRequired,
                from_street: PropTypes.string.isRequired,
                to_zip: PropTypes.string.isRequired,
                to_state: PropTypes.string.isRequired,
                shipping: PropTypes.number.isRequired,
                amount: PropTypes.number.isRequired,
                to_country: PropTypes.string.isRequired,
            }).isRequired,
            key: PropTypes.string.isRequired,
        }).isRequired,
        date: PropTypes.string.isRequired,
    }),
};

export default TaxCalculationModal;
