import * as Sentry from "@sentry/react";
import '@kjdelectronics/ps-quotetool-domain/util/prototypes/index.js'
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {ErrorProvider} from "./context/ErrorContext";
import reportWebVitals from './reportWebVitals';

Sentry.init({
    dsn: "https://3ba77bdece9913d9c874e5e5217f8a3f@o4508891933048832.ingest.us.sentry.io/4508892382822400",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    profilesSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0.0,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ErrorProvider>
            <App />
        </ErrorProvider>
    </React.StrictMode>
);

reportWebVitals();