import React from 'react';
import PropTypes from 'prop-types';

const taxGroupOptions = [
    { id: 0, name: "Default Zone (everywhere else)" },
    { id: 1, name: "GST 5 - AB, MB, NT, NU, YT" },
    { id: 2, name: "HST 13 - ON" },
    { id: 3, name: "HST 15 - NB, NL, NS, PE" },
    { id: 4, name: "SK: GST+PST 11" },
    { id: 5, name: "SK: GST only 5" },
    { id: 6, name: "BC: GST+PST 12" },
    { id: 7, name: "BC: GST only 5" },
    { id: 8, name: "QC: GST+QST 14.975" },
    { id: 9, name: "QC: GST only 5" },
    { id: 10, name: "Canada: Exempt all taxes" },
];

const TaxGroupDropdown = ({ taxGroup, onTaxGroupChange, readOnly, tabIndex }) => {
    if (readOnly) {
        // Renders a non-interactive display if readOnly is true
        return (
            <div className="tax-group-dropdown">
                <label style={{ marginRight: '5px' }}>Tax Group:</label>
                <span>{taxGroup ? taxGroup.name : ''}</span>
            </div>
        );
    }

    const handleChange = (event) => {
        const selectedId = parseInt(event.target.value, 10);
        const selectedOption = taxGroupOptions.find(option => option.id === selectedId);
        if (onTaxGroupChange) {
            onTaxGroupChange(selectedOption);
        }
    };

    return (
        <div className="tax-group-dropdown">
            <label htmlFor="taxGroupSelect" style={{ marginRight: '5px' }}>Tax Group:</label>
            <select
                id="taxGroupSelect"
                value={taxGroup ? taxGroup.id : ''}
                onChange={handleChange}
                tabIndex={tabIndex}
            >
                {taxGroupOptions.map(option => (
                    <option key={option.id} value={option.id}>
                        {option.name}
                    </option>
                ))}
            </select>
        </div>
    );
};

TaxGroupDropdown.propTypes = {
    taxGroup: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }),
    onTaxGroupChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    tabIndex: PropTypes.number,
};

export default TaxGroupDropdown;