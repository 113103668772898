import React from 'react';
import PropTypes from "prop-types";
import {Button, Col, Row} from "reactstrap";
import { DatePicker } from "reactstrap-date-picker";
import moment from "moment/moment";
import TaxableSwitch from "../atoms/inputs/TaxableSwitch.js";
import QuoteDetailAddresses from "./QuoteDetailAddresses.js";
import EditableLabelWithPencilIcon from "../atoms/EditableLabelWithPencilIcon.js";
import AccountSelector from "../organism/AccountSelector.js";
import AccountTaxableConflictWarning from "../atoms/AccountTaxableConflictWarning.js";
import {getCompanySegment} from "@kjdelectronics/ps-quotetool-domain/domain/helper/companyUtils.js";
import TaxGroupDropdown from "../atoms/inputs/TaxGroupDropdown";

const QuoteDetail = ({ quote, accountId, patchQuote, quoteToolClient, patchQuoteAction }) => {

    const setAccount = async (selectedAccount) => {
        patchQuote({
            keyArray: ['account'],
            newValue: selectedAccount
        });

        if (quote.account.id == null) {
            setTimeout(() => applyAddressDefaultsFromAccount(selectedAccount), 50);
            setTimeout(() => applyTaxableDefaultFromAccount(selectedAccount), 200);
            setTimeout(() => applyPaymentTermsDefaultFromAccount(selectedAccount), 400);
        }
    };

    const applyAddressDefaultsFromAccount = (selectedAccount) => {
        const defaultShippingAddress = selectedAccount.getAddressById(selectedAccount.defaults.shippingAddressId);
        const defaultBillingAddress = selectedAccount.getAddressById(selectedAccount.defaults.billingAddressId);
        let isBillingSameAsShippingAddress = true;
        if (defaultShippingAddress?.id !== defaultBillingAddress?.id) {
            isBillingSameAsShippingAddress = false;
        }

        patchQuote({
            keyArray: ['addresses'],
            newValue: {
                shippingAddress: defaultShippingAddress ? defaultShippingAddress : quote.addresses.shippingAddress,
                billingAddress: defaultBillingAddress ? defaultBillingAddress : quote.addresses.billingAddress,
                isBillingSameAsShippingAddress
            }
        });
    };

    const applyTaxableDefaultFromAccount = (selectedAccount) => {
        patchQuote({
            keyArray: ['detail', 'isTaxable'],
            newValue: selectedAccount.isTaxable
        });
    };

    const applyPaymentTermsDefaultFromAccount = (selectedAccount) => {
        if(selectedAccount.meta.paymentTermId){
            console.log("Payment term id", selectedAccount.meta.paymentTermId)
            patchQuote({
                keyArray: ['detail', 'paymentTermId'],
                newValue: selectedAccount.meta.paymentTermId
            });
        }
    };

    const companySeg = getCompanySegment(quote.account).toUpperCase();

    const computedIsTaxable =
        companySeg === "CA"
            ? !(quote.account.taxes &&
                quote.account.taxes.taxGroup &&
                quote.account.taxes.taxGroup.id === 10)
            : (quote.detail.isTaxable !== undefined
                ? quote.detail.isTaxable
                : (quote.account &&
                quote.account.taxes &&
                quote.account.taxes.isTaxable !== undefined
                    ? quote.account.taxes.isTaxable
                    : true));

    return (
        <div className="ms-3 me-3 small-form-container">
            <h3>Quote Detail </h3>
            <div className="ml-1">
                <EditableLabelWithPencilIcon
                    defaultValue={quote.meta?.quoteTitle}
                    placeholderValue={"Quote title"}
                    handleNewValue={async (newValue) => {
                        await patchQuote({ keyArray: ["meta", "quoteTitle"], newValue });
                    }}
                />
            </div>
            <div className="row mt-1">
                <div className="col-md-6">
                    {/* Left Column */}
                    <AccountSelector
                        quote={quote}
                        setAccount={setAccount}
                        defaultAccountId={accountId}
                        quoteToolClient={quoteToolClient}
                        showDocumentsLabel={true}
                    />

                    <Row className="align-items-center">
                        <Col lg={3} md={6}>
                            <TaxableSwitch
                                isTaxable={computedIsTaxable}
                                onChange={newValue => {
                                    if (companySeg !== "CA") {
                                        patchQuote({
                                            keyArray: ["detail", "isTaxable"],
                                            newValue
                                        });
                                    }
                                }}
                                disabled={companySeg === "CA"}
                                text="Taxable"
                            />
                        </Col>
                        {/* For CA accounts, add a read-only dropdown showing the account-level tax group */}
                        {companySeg === "CA" && (
                            <Col lg={3} md={6}>
                                <TaxGroupDropdown
                                    taxGroup={quote.account.taxes?.taxGroup || { id: 0, name: "Default Zone (everywhere else)" }}
                                    readOnly={true}
                                />
                            </Col>
                        )}
                        <Col className="mt-4">
                            {quote.account && (
                                <AccountTaxableConflictWarning
                                    account={quote.account}
                                    isTaxable={computedIsTaxable}
                                    type={"quote"}
                                />
                            )}
                        </Col>
                    </Row>
                </div>
                <div className="col-md-6" style={{marginLeft: '0px', paddingLeft: '100px'}}>
                    {/* Right Column */}
                    {/* Date */}
                    <Row className="mb-1">
                        <Col sm={3}>
                            <label htmlFor="quoteDate" className="">Date</label>
                        </Col>
                        <Col sm={5}>
                            <DatePicker
                                id="quoteDate"
                                value={
                                    quote.detail.date
                                        ? moment(quote.detail.date).toISOString()
                                        : ''
                                }
                                showClearButton={false}
                                onChange={(value) =>
                                    patchQuote({
                                        keyArray: ["detail", "date"],
                                        newValue: value ? new Date(value) : null,
                                    })
                                }
                                dateFormat="YYYY/MM/DD" // Display in desired format
                            />
                        </Col>
                        <Col sm={4} className="ms-0">
                            <Button
                                className="today-button mx-2 py-1 mt-1 ms-0"
                                onClick={() => patchQuote({
                                    keyArray: ["detail", "date"],
                                    newValue: new Date()
                                })}
                            >
                                Today
                            </Button>
                        </Col>
                    </Row>
                    {/* Expiration */}
                    <Row>
                        <Col sm={3}>
                            <label htmlFor="expiresAt" className="col-sm-3 col-form-label">Expiration</label>
                        </Col>
                        <Col sm={5}>
                            <DatePicker
                                id="expiresAt"
                                value={
                                    quote.detail.expiresAt
                                        ? moment(quote.detail.expiresAt).toISOString()
                                        : ''
                                }
                                showClearButton={true}
                                onChange={(value) =>
                                    patchQuote({
                                        keyArray: ["detail", "expiresAt"],
                                        newValue: value ? new Date(value) : null,
                                    })
                                }
                                dateFormat="YYYY/MM/DD" // Display in desired format
                            />
                        </Col>
                        <Col sm={4} className="ms-0">
                            <Button
                                className="thirty-day-button mx-2 py-1 mt-1 ms-0"
                                onClick={() => patchQuote({
                                    keyArray: ["detail", "expiresAt"],
                                    newValue: moment().add(30, 'days').toDate(),
                                })}
                            >
                                30 Days
                            </Button>
                            <Button
                                className="ninety-day-button mx-2 py-1 mt-1"
                                onClick={() => patchQuote({
                                    keyArray: ["detail", "expiresAt"],
                                    newValue: moment().add(90, 'days').toDate(),
                                })}
                            >
                                90 Days
                            </Button>
                        </Col>
                    </Row>
                    {/* PO Number */}
                    <Row className="mt-1">
                        <Col sm={3}>
                            <label htmlFor="poNumber" className="col-sm-3 col-form-label">PO #</label>
                        </Col>
                        <Col className="ms-0" sm={5}>
                            <input type="text" className="form-control input-group-sm" placeholder="Purchase Order Number"
                                   value={quote.detail.purchaseOrderNumber}
                                   onChange={(e) =>
                                       patchQuote({
                                           keyArray: ["detail", "purchaseOrderNumber"],
                                           newValue:  e?.target?.value ? e.target.value : null,
                                       })
                                   }
                            />
                        </Col>
                    </Row>
                </div>
            </div>
            <QuoteDetailAddresses
                quote={quote}
                patchQuote={patchQuote}
                patchQuoteAction={patchQuoteAction}
                quoteToolClient={quoteToolClient}
            />
        </div>
    );
};

QuoteDetail.propTypes = {
    quote: PropTypes.object.isRequired,
    accountId: PropTypes.string,
    patchQuote: PropTypes.func.isRequired,
    patchQuoteAction: PropTypes.func.isRequired,
    quoteToolClient: PropTypes.object.isRequired,
};

export default QuoteDetail;