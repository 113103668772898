import React, { useState, useEffect } from 'react';
import { Button, InputGroup, InputGroupText } from 'reactstrap';
import PropTypes from "prop-types";
import NumericInput from "../atoms/inputs/NumericInput.js";
import TaxCalculationModal from "./TaxCalculationModal.js"; // or whichever NumericInput library you use

function QuoteManualTaxOverride({
                               quote,
                               patchQuote,
                               currencyFormatter,
                               currencyCode
                           }) {
    const manualTaxOverride = quote?.taxes?.manualTaxOverride ?? null;
    const defaultTaxValue = quote?.totals?.taxes ?? 0;


    // If there's a manual override, use it; otherwise use the default from totals.
    const displayedTaxValue =
        manualTaxOverride !== null ? manualTaxOverride : defaultTaxValue;

    // Local state to track what's typed in the NumericInput
    const [taxValue, setTaxValue] = useState(displayedTaxValue);
    const [isTaxesModalOpen, setIsTaxesModalOpen] = useState(false);


    // Whenever the displayed tax changes outside of this component,
    // sync it to local state so we show the correct value.
    useEffect(() => {
        setTaxValue(displayedTaxValue);
    }, [displayedTaxValue]);

    // Clicking "Manual Tax" sets manualTaxOverride to the current default tax
    const handleManualTaxClick = () => {
        patchQuote({
            quote: quote,
            keyArray: ['taxes', 'manualTaxOverride'],
            newValue: defaultTaxValue,
        });
    };

    // Clicking "Auto Tax" reverts manualTaxOverride to null
    const handleAutoTaxClick = () => {
        patchQuote({
            quote: quote,
            keyArray: ['taxes', 'manualTaxOverride'],
            newValue: null,
        });
    };

    // Called when NumericInput loses focus
    const handleTaxBlur = (value) => {
        // If the user clears the input completely, fallback
        if (value === '') {
            patchQuote({
                quote: quote,
                keyArray: ['taxes', 'manualTaxOverride'],
                newValue: 0,
            });
            return;
        }

        const newValue = parseFloat(value) || 0;
        patchQuote({
            quote: quote,
            keyArray: ['taxes', 'manualTaxOverride'],
            newValue: newValue,
        });
    };

    // Track local changes as the user types
    const handleTaxChange = (value) => {
        setTaxValue(value);
    };

    const getTaxes = () => {
        if(!quote.taxes.savedCalculation)
            return `${currencyFormatter(defaultTaxValue)} ${currencyCode}`
        return <Button color="link" onClick={() => setIsTaxesModalOpen(true)}>{currencyFormatter(defaultTaxValue)} {currencyCode}</Button>
    }

    return (
        <div>
            <TaxCalculationModal isOpen={isTaxesModalOpen}
                                 taxData={quote.taxes.savedCalculation}
                                 currencyFormatter={currencyFormatter}
                                 currencyCode={currencyCode}
                                 toggle={() => setIsTaxesModalOpen(!isTaxesModalOpen)}/>
            {/* If there's no manual override, show the default tax and "Manual Tax" button */}
            {manualTaxOverride === null ? (
                <div className="d-flex justify-content-end ">
                    <Button
                        outline={false}
                        color="secondary"
                        className="ms-2"
                        bsSize="sm"
                        style={{fontSize: 10, padding: 4}}
                        onClick={handleManualTaxClick}
                    >
                        Manual Tax
                    </Button>
                    <span>
                        {getTaxes()}
                  </span>

                </div>
            ) : (
                // Otherwise, show the override input and "Auto Tax" button
                <div className="d-flex justify-content-end ">
                    <Button
                        outline={false}
                        color="secondary"
                        className="ms-2"
                        bsSize="sm"
                        style={{fontSize: 10, padding: 4}}
                        onClick={handleAutoTaxClick}
                    >
                        Auto Tax
                    </Button>
                    <InputGroup style={{maxWidth: '16ch'}}>
                        <InputGroupText className="small-padding-input-group">
                            $
                        </InputGroupText>
                        <NumericInput
                            value={taxValue}
                            onChange={handleTaxChange}
                            onBlur={handleTaxBlur}
                            className="text-end me-4"
                            style={{
                                width: '35%',
                                minWidth: '8ch',
                            }}
                            decimalPlaces={2}
                        />
                    </InputGroup>
                </div>
            )}
        </div>
    );
}

QuoteManualTaxOverride.propTypes = {
    quote: PropTypes.shape({
        taxes: PropTypes.shape({
            manualTaxOverride: PropTypes.number,
        }),
        totals: PropTypes.shape({
            taxes: PropTypes.number,
        }),
    }),
    patchQuote: PropTypes.func.isRequired,
    currencyFormatter: PropTypes.func.isRequired,
    currencyCode: PropTypes.string.isRequired,
};

export default QuoteManualTaxOverride;
