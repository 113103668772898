import React from 'react';
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import LockableLineItemField from "../atoms/inputs/LockableLineItemField.js";
import { Button, Input, InputGroup, InputGroupText, Badge, Tooltip } from "reactstrap";
import TitleSearchModal from "../molecules/modals/TitleSearchModal";
import { percentFormatter, usdFormatter, cadFormatter, eurFormatter, gbpFormatter } from "../../helper/formatters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faInfoCircle, faPencilAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import NumericInput from "../atoms/inputs/NumericInput";
import ProductPhysicalDetailsIconButton from "../atoms/ProductPhysicalDetailsIconButton.js";
import QuoteLineItem from "@kjdelectronics/ps-quotetool-domain/obj/quote/QuoteLineItem.js";

class QuoteLineItemRow extends React.Component {
    constructor(props) {
        super(props);
        this.skuInputRef = React.createRef();
        // For items added via bulk-add, props.lineItem.isFromMultipleSkuModal will be defined (true).
        this.state = {
            locked: !!props.lineItem.productId,
            skuLocked: (typeof props.lineItem.isFromMultipleSkuModal === 'boolean'
                ? props.lineItem.isFromMultipleSkuModal
                : false) || !!props.lineItem.productId,
            titleLocked: (typeof props.lineItem.isFromMultipleSkuModal === 'boolean'
                ? props.lineItem.isFromMultipleSkuModal
                : false) || !!props.lineItem.productId,
            hasBlurred: false,
            hasBeenLocked: false,
            loading: false,
            productSearchResults: null,
            productDetails: null,
            isTitleSearchModalOpen: false,
            filteredResults: [],
            userInput: '',
            skuSearchTimeout: null,
            tooltipOpen: false,
            quantityInput: props.lineItem.quantity.toString(),
            quantityError: false,
            editingDiscount: props.lineItem.discount ? props.lineItem.discount.toString() : '',
            editingPrice: props.lineItem.price ? props.lineItem.price.toString() : '',
            backorderTooltipOpen: false,
            isEditing: false,
        };

        this.handleSkuKeyDown = this.handleSkuKeyDown.bind(this);
        this.handleDiscountChange = this.handleDiscountChange.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.isEditing) {
            return null;
        }
        const hasProduct = !!(nextProps.lineItem.productId || nextProps.lineItem.id);
        const fromProps = typeof nextProps.lineItem.isFromMultipleSkuModal === 'boolean'
            ? nextProps.lineItem.isFromMultipleSkuModal
            : false;
        return {
            skuLocked: fromProps || hasProduct,
            titleLocked: fromProps || hasProduct,
        };
    }

    async handleLineItemChange(index, field, value) {
        this.props.patchLineItem({
            lineItemIndex: index,
            key: field,
            value
        });

        if (field === 'sku') {
            clearTimeout(this.state.skuSearchTimeout);
            if (!value.trim()) {
                return;
            }
            this.setState({
                skuSearchTimeout: setTimeout(() =>
                        this._handleProductSkuChanged({ index, sku: value }, { requireSkuFieldToMatch: false })
                            .catch(err => {}),
                    250
                )
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { quantity, price, discount, retailPrice } = this.props.lineItem;
        const newQuantityInput = quantity != null ? quantity.toString() : '';
        const newEditingPrice = price != null ? price.toString() : '';
        const newEditingDiscount = discount != null ? discount.toString() : '';

        if (prevProps.lineItem.discount !== discount && this.state.editingDiscount === '') {
            this.setState({ editingDiscount: newEditingDiscount });
        }

        if (retailPrice && newEditingPrice) {
            const numericEditingPrice = parseFloat(newEditingPrice);
            const possibleDiscounts = [0, 5, 10, 20];
            for (let d of possibleDiscounts) {
                const expectedPrice = retailPrice * (1 - d / 100);
                if (Math.abs(expectedPrice - numericEditingPrice) < 0.01) {
                    if (this.state.editingDiscount !== d.toString()) {
                        this.setState({ editingDiscount: d.toString() });
                    }
                    break;
                }
            }
        }

        if (
            (prevProps.lineItem.quantity !== quantity && this.state.quantityInput !== newQuantityInput) ||
            (prevProps.lineItem.price !== price && this.state.editingPrice !== newEditingPrice)
        ) {
            this.setState({
                quantityInput: newQuantityInput,
                editingPrice: newEditingPrice,
            });
        }
    }

    async _handleProductSkuChanged({ index, sku }, { requireSkuFieldToMatch = false } = {}) {
        try {
            if (!this.props.quote) {
                console.error("No quote available to patch.");
                return;
            }
            const newLineItemObject = await this.props.quoteToolClient.getProductLineItemBySku({
                companyUrlSegment: this.props.companyUrlSegment,
                sku
            });

            if (!newLineItemObject.productId) {
                // Optionally assign a dummy productId or use the SKU as the ID so that getDerivedStateFromProps sees it
                newLineItemObject.productId = sku;
            }

            newLineItemObject.isFromMultipleSkuModal = true;

            this.props.setLineItem({
                lineItemIndex: index,
                newLineItemObject
            });

            this.setState({
                skuLocked: true,
                titleLocked: true,
                hasBlurred: true,
                hasBeenLocked: true,
                isEditing: false,
            }, () => {
                if (this.skuInputRef.current) {
                    setTimeout(() => {
                        this.skuInputRef.current.blur();
                    }, 0);
                }
            });

        } catch (error) {
            if (error.code === 404) {
                const newLineItemObject = new QuoteLineItem(this.props.quote.lineItems[index], this.props.quote);
                newLineItemObject.title = '';
                newLineItemObject.productId = null;
                this.props.setLineItem({
                    lineItemIndex: index,
                    newLineItemObject
                });
                return;
            }
            console.error('Error fetching product line item:', error);
            toast.error(`Unable to load product data for SKU ${sku}.`);
        }
    }

    handleProductSelected = async (sku, title) => {
        this.setState({ loading: true });
        try {
            await this._handleProductSkuChanged({ index: this.props.index, sku });
            this.setState({ locked: true });
        } catch (err) {
            console.log(`Error while populating product from search. Row will not be locked.`, err);
            this.setState({ locked: false });
        } finally {
            this.setState({ loading: false });
        }
    };

    toggleTooltip = () => {
        this.setState(prevState => ({
            tooltipOpen: !prevState.tooltipOpen
        }));
    };

    handleTitleKeyDown = (e) => {
        if (e.key === 'Enter') {
            const inputValue = e.target.value.trim();
            this.performTitleSearch(inputValue);
        }
    };

    performTitleSearch = async (inputValue) => {
        if (!inputValue) return;
        try {
            const companyUrlSegment = this.props.companyUrlSegment.toLowerCase();
            const lineItems = await this.props.quoteToolClient.searchProductLineItemsByName({
                companyUrlSegment,
                name: inputValue
            });
            this.setState({
                isTitleSearchModalOpen: lineItems.length > 0,
                productSearchResults: lineItems
            });
        } catch (error) {
            console.error("Error searching for product:", error);
            toast.error(
                <div>
                    <div>
                        <b>Product Search Error</b>
                    </div>
                    {error.userMessage || error.message}
                </div>
            );
        }
    };

    handleSkuKeyDown = async (e) => {
        if (e.key === 'Enter') {
            const inputValue = e.target.value.trim().toLowerCase();
            if (!inputValue) {
                return;
            }
            try {
                const searchResults = await this.props.quoteToolClient.getSaturnProductBySkuForCompanyId({
                    sku: inputValue,
                    companyId: this.props.companyId
                });
                this.setState({ isTitleSearchModalOpen: searchResults && searchResults.length > 0, productSearchResults: searchResults });
            } catch (error) {
                if (inputValue) {
                    toast.error(
                        <div>
                            <div>
                                <b>Product Search Error</b>
                            </div>
                            {error.userMessage}
                        </div>
                    );
                    console.error('Error searching for product:', error);
                }
            }
        }
    };

    _searchButtonOnClick = () => {
        const titleValue = this.props.lineItem.title.trim();
        this.performTitleSearch(titleValue);
    };

    handleDiscountButtonClick(index, value) {
        this.setState({ editingDiscount: value.toString() }, () => {
            this.handleDiscountBlur(index);
        });
    }

    handleDiscountChange = (value) => {
        this.setState({ editingDiscount: value });
    };

    handleQuantityChange(index, value) {
        if (value === '') {
            this.props.patchLineItem({
                lineItemIndex: index,
                key: 'quantity',
                value: 0
            });
            this.setState({
                quantityError: false,
                quantityInput: ''
            });
            return;
        }

        const quantity = parseInt(value, 10);
        const isOverQuantity = quantity > (this.props.lineItem.live || 0);

        this.props.patchLineItem({
            lineItemIndex: index,
            key: 'quantity',
            value: quantity
        });

        this.setState({
            quantityError: isOverQuantity,
            quantityInput: quantity.toString()
        });
    }

    handleQuantityBlur = (index) => {
        const { lineItem } = this.props;
        const quantity = parseFloat(this.state.quantityInput) || 0;
        this.handleQuantityChange(index, quantity);
    };

    handlePriceBlur = (index) => {
        const { editingPrice } = this.state;
        const parsedValue = parseFloat(editingPrice);
        const valueToUpdate = isNaN(parsedValue) ? 0 : parsedValue;
        this.props.updateValue(valueToUpdate, ['lineItems', index, 'price'], 2);
    };

    handlePriceChange = (value) => {
        this.setState({ editingPrice: value });
    };

    handleBackorderChange = () => {
        const { index, lineItem } = this.props;
        console.log(!lineItem.isBackordered);
        this.props.patchLineItem({
            lineItemIndex: index,
            key: 'isBackordered',
            value: !lineItem.isBackordered
        });
    };

    toggleBackorderTooltip = () => {
        this.setState(prevState => ({ backorderTooltipOpen: !prevState.backorderTooltipOpen }));
    };

    handleDiscountBlur(index) {
        const { editingDiscount } = this.state;
        const parsedValue = parseInt(editingDiscount, 10);
        const validValue = isNaN(parsedValue) ? 0 : parsedValue;
        this.props.patchLineItem({
            lineItemIndex: index,
            key: 'discount',
            value: validValue
        });
    }

    toggleEdit = () => {
        this.setState({
            skuLocked: false,
            titleLocked: false,
            isEditing: true,
        });
    };

    _renderAvailable(available) {
        if (isNaN(available))
            return <span className="small-italic">Not Avail</span>;
        const left = available - this.props.lineItem.quantity;
        return `${available} (${left})`;
    }

    render() {
        const { index, lineItem, quote, lineNumber, companyId } = this.props;
        const { quantityError } = this.state;
        const isArchived = !!lineItem.isArchived;
        let available;
        if (lineItem.id)
            available = lineItem.live;
        else
            available = "";
        const { editingDiscount } = this.state;
        const skuValue = lineItem.sku ? lineItem.sku.toString() : "";
        const unitCostValue = lineItem.unitCost !== undefined && lineItem.unitCost !== null ? lineItem.unitCost.toString() : "";
        const retailPriceValue = lineItem.retailPrice !== undefined && lineItem.retailPrice !== null ? lineItem.retailPrice.toString() : "";
        const currencyFormatter = usdFormatter;
        const isDuplicate = quote.lineItems.filter(item => item.sku === lineItem.sku).length > 1;
        const profitClass = lineItem.profitTotal >= 0 ? 'profit-positive' : 'profit-negative';
        const inventoryClass = available - lineItem.quantity <= 0 ? 'profit-negative' : '';

        return (
            <tr key={lineItem.id || lineItem.tempId || index}>
                <td>{lineNumber}</td>
                <td>
                    <InputGroup>
                        <div className="sku-label">
                            <LockableLineItemField
                                ref={this.skuInputRef}
                                value={skuValue}
                                isLocked={this.state.skuLocked}
                                inputProperties={{
                                    onChange: (e) => this.handleLineItemChange(index, 'sku', e.target.value),
                                    style: { color: isDuplicate ? 'red' : 'black' }
                                }}
                                onBlurHandler={() => this.setState({ skuLocked: true, titleLocked: true, hasBlurred: true, isEditing: false })}
                            />
                            {this.state.skuLocked && this.state.titleLocked && (
                                <FontAwesomeIcon
                                    icon={faPencilAlt}
                                    onMouseDown={(e) => e.preventDefault()}
                                    onClick={this.toggleEdit}
                                    style={{ marginLeft: '8px', cursor: 'pointer' }}
                                />
                            )}
                            {lineItem.sku && isDuplicate && <Badge color="danger" pill>Duplicate SKU</Badge>}
                            {isArchived && <Badge color="danger" pill>Archived SKU</Badge>}
                        </div>
                    </InputGroup>
                </td>
                <td>
                    <InputGroup>
                        <LockableLineItemField
                            value={lineItem.title}
                            isLocked={this.state.titleLocked}
                            inputProperties={{
                                onChange: (e) => this.handleLineItemChange(index, 'title', e.target.value),
                                onBlur: () =>
                                    this.setState({ skuLocked: true, titleLocked: true, hasBlurred: true, isEditing: false }),
                                onKeyDown: this.handleTitleKeyDown,
                            }}
                        />
                        {this.state.titleLocked ? (
                            <FontAwesomeIcon
                                icon={faPencilAlt}
                                onMouseDown={(e) => e.preventDefault()}
                                onClick={this.toggleEdit}
                                style={{ marginLeft: '8px', cursor: 'pointer' }}
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faSearch}
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                    this._searchButtonOnClick();
                                }}
                                style={{ marginLeft: '8px', cursor: 'pointer' }}
                            />
                        )}
                    </InputGroup>
                    {this.state.isTitleSearchModalOpen && (
                        <TitleSearchModal
                            isOpen={this.state.isTitleSearchModalOpen}
                            productsData={this.state.productSearchResults}
                            onClose={() => this.setState({ isTitleSearchModalOpen: false })}
                            onProductSelected={this.handleProductSelected}
                        />
                    )}
                </td>
                <td>
                    <NumericInput
                        value={this.state.quantityInput}
                        onChange={(value) => this.handleQuantityChange(index, value)}
                        onBlur={() => this.handleQuantityBlur(index)}
                        decimalPlaces={0}
                        className="text-center"
                        style={{
                            borderColor: quantityError ? 'red' : 'initial',
                            minWidth: '7ch',
                            maxWidth: '50px'
                        }}
                    />
                </td>
                <td className={`inventory-level ${inventoryClass}`}>
                    {this._renderAvailable(available)}
                    {lineItem.sku && (
                        <>
                            <Input
                                type="checkbox"
                                checked={lineItem.isBackordered}
                                onClick={this.handleBackorderChange}
                                id={`backorder-checkbox-${index}`}
                                style={{ outline: '1px solid black', marginLeft: '3px' }}
                            />
                            <Tooltip
                                placement="top"
                                isOpen={this.state.backorderTooltipOpen}
                                target={`backorder-checkbox-${index}`}
                                toggle={this.toggleBackorderTooltip}
                            >
                                Backordered Status
                            </Tooltip>
                        </>
                    )}
                </td>
                <td>
                    <LockableLineItemField
                        value={unitCostValue}
                        lockedFormatter={currencyFormatter}
                        isLocked={!!this.props.lineItem.id}
                        inputProperties={{
                            type: "number",
                            onChange: (e) => this.handleLineItemChange(index, 'unitCostUsd', parseFloat(e.target.value))
                        }}
                    />
                </td>
                <td>
                    {lineItem.salePrice ? (
                        <>
                            <LockableLineItemField
                                value={lineItem.salePrice.toString()}
                                lockedFormatter={currencyFormatter}
                                isLocked={!!this.props.lineItem.id}
                                inputProperties={{
                                    type: "number",
                                    onChange: (e) => this.handleLineItemChange(index, 'retailPrice', parseFloat(e.target.value))
                                }}
                                className="sale-price"
                            />
                            <FontAwesomeIcon
                                icon={faInfoCircle}
                                id={`tooltip-${index}`}
                                style={{ marginLeft: '5px', color: 'blue', cursor: 'pointer' }}
                            />
                            <Tooltip
                                placement="top"
                                isOpen={this.state.tooltipOpen}
                                target={`tooltip-${index}`}
                                toggle={this.toggleTooltip}
                            >
                                Promotional Price! Normally: ${lineItem.price || lineItem.retailPrice}
                            </Tooltip>
                        </>
                    ) : (
                        <LockableLineItemField
                            value={retailPriceValue}
                            lockedFormatter={currencyFormatter}
                            isLocked={true}
                            inputProperties={{
                                type: "number",
                                onChange: (e) => this.handleLineItemChange(index, 'retailPrice', parseFloat(e.target.value))
                            }}
                        />
                    )}
                </td>
                <td>
                    <div className="discount-button-grid">
                        {[0, 5, 10, 20].map((discountVal) => (
                            <Button
                                key={discountVal}
                                color={parseInt(this.state.editingDiscount) === discountVal ? 'primary' : 'secondary'}
                                onClick={() => this.handleDiscountButtonClick(this.props.index, discountVal)}
                                style={{ width: '100%', margin: '0px', padding: '1px', fontSize: '0.8em' }}
                            >
                                {discountVal}%
                            </Button>
                        ))}
                    </div>
                </td>
                <td>
                    <InputGroup>
                        <InputGroupText className="small-padding-input-group">$</InputGroupText>
                        <NumericInput
                            value={this.state.editingPrice}
                            onChange={this.handlePriceChange}
                            onBlur={() => this.handlePriceBlur(index)}
                            style={{ minWidth: '9ch', maxWidth: '9ch' }}
                        />
                    </InputGroup>
                </td>
                <td className={profitClass}>
                    {currencyFormatter(lineItem.profitTotal)} ({percentFormatter(lineItem.profitPercentage)})
                </td>
                <td>{currencyFormatter(lineItem.extendedLineTotal)}</td>
                <td>
                    <div className="action-icons">
                        <Button
                            color="link"
                            style={{ color: "#dc3545" }}
                            tabIndex="-1"
                            onClick={() => {
                                this.props.patchQuoteAction({
                                    action: "removeItem",
                                    data: { index }
                                });
                            }}
                        >
                            <FontAwesomeIcon icon={faX} className="action-icon" />
                        </Button>
                        <ProductPhysicalDetailsIconButton
                            lineItem={this.props.lineItem}
                            quote={quote}
                        />
                    </div>
                </td>
            </tr>
        );
    }
}

QuoteLineItemRow.propTypes = {
    quoteToolClient: PropTypes.object.isRequired,
    lineItem: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    patchQuote: PropTypes.func.isRequired,
    patchQuoteAction: PropTypes.func.isRequired,
    patchLineItem: PropTypes.func.isRequired,
    setLineItem: PropTypes.func.isRequired,
    quote: PropTypes.object.isRequired,
    lineNumber: PropTypes.number.isRequired,
    companyId: PropTypes.number.isRequired,
    isUSCompany: PropTypes.bool.isRequired,
    companyUrlSegment: PropTypes.string.isRequired,
};

export default QuoteLineItemRow;